<template>
  <div>
    <v-row>
      <v-col cols="6">
        <v-select
          :value="value"
          :items="tableFilters"
          :item-value="item => {
            if (item.attributes) return { tableFilterId: item.id }
          }"
          :value-comparator="(a, b) => {
            if (a && b) return +a.tableFilterId === +b.tableFilterId
          }"
          item-text="attributes.name"
          label="卓フィルタ"
          placeholder="指定なし"
          persistent-placeholder
          no-data-text="選択可能なデータがありません"
          :suffix="value.tableFilterId ? 'の卓の' : ''"
          clearable
          :menu-props="{ auto: true, offsetY: true }"
          @change="change('tableFilterId', $event ? $event.tableFilterId : null)"
          @click:clear="change('tableFilterId', null)"
        />
      </v-col>

      <v-col cols="6">
        <v-select
          :value="value.calcSalesTarget"
          :items="calcSalesTargets"
          item-value="value"
          item-text="text"
          label="算出対象範囲"
          :error="!isValid.calcSalesTarget"
          :menu-props="{ auto: true, offsetY: true }"
          @change="change('calcSalesTarget', $event)"
        >
          <template #selection="{ item }">
            <span>
              <v-icon
                v-if="item.icon"
                left
                small
              >
                {{ item.icon }}
              </v-icon>
              {{ item.text }}
            </span>
          </template>

          <template #item="{ item }">
            <v-list-item-avatar
              v-if="item.icon"
              size="20"
            >
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title>
                {{ item.text }}
              </v-list-item-title>
            </v-list-item-content>
          </template>
        </v-select>

        <v-checkbox
          :input-value="value.withTax"
          label="大計"
          class="mt-0 pt-0"
          :ripple="false"
          @change="change('withTax', !!$event)"
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col
        md="6"
        cols="12"
      >
        <v-text-field
          :value="value.amount * 100.0"
          type="number"
          inputmode="numeric"
          pattern="[0-9]*"
          :max="100"
          :error="!isValid.amount"
          label="バック額"
          suffix="%"
          min="1"
          @change="change('amount', $event / 100.0)"
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col
        md="6"
        cols="12"
      >
        <v-select
          :value="value.roundingMethod"
          :items="roundingMethods"
          item-value="value"
          item-text="text"
          label="丸め処理"
          :error="!isValid.roundingMethod"
          mandatory
          :menu-props="{ auto: true, offsetY: true }"
          @change="change('roundingMethod', $event)"
        />
      </v-col>

      <v-spacer />

      <v-col
        md="6"
        cols="12"
      >
        <v-slider
          :value="Math.abs(value.roundingDigit)"
          :tick-labels="['0', '一', '十', '百', '千', '万']"
          :min="0"
          :max="5"
          label="丸める位"
          step="1"
          ticks="always"
          tick-size="4"
          :error="!isValid.roundingDigit"
          @change="change('roundingDigit', $event * -1)"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import {
  ref,
  computed,
  onMounted,
} from '@vue/composition-api'
import { useVModel } from '@vueuse/core'
import {
  chain,
  values,
  every,
  has,
  groupBy,
  each,
} from 'lodash'
import {
  mdiCashMarker,
  mdiHeartOutline,
} from '@mdi/js'
import useCurrentData from '@/views/composable/useCurrentData'

export default {
  props: {
    value: {
      type: Object,
      required: true,
      default: () => {},
    },
    tableFilters: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  setup(props, { emit }) {
    const salesRebateForm = useVModel(props, 'value')
    if (!has(salesRebateForm.value, 'amount')) salesRebateForm.value.amount = 0.1
    if (!has(salesRebateForm.value, 'roundingMethod')) salesRebateForm.value.roundingMethod = 'round'
    if (!has(salesRebateForm.value, 'roundingDigit')) salesRebateForm.value.roundingDigit = 0
    if (!has(salesRebateForm.value, 'calcSalesTarget')) salesRebateForm.value.calcSalesTarget = 'contributed'

    const changedCount = ref(0) // NOTE: valueが変更されるたびに再computeするために（propはリアクティブじゃないので）

    const isValid = computed(() => {
      // eslint-disable-next-line no-unused-expressions
      changedCount.value // NOTE: 単純な参照のみで変更を検知して再算出してくれる

      const {
        amount,
        roundingMethod,
        roundingDigit,
        calcSalesTarget,
      } = salesRebateForm.value

      return {
        amount: amount && amount > 0 && amount <= 1.0,
        roundingMethod,
        roundingDigit: roundingDigit <= 0,
        calcSalesTarget,
      }
    })

    const change = (key, val) => {
      salesRebateForm.value[key] = val

      changedCount.value += 1

      emit('validate', chain(isValid.value).values().every(bool => bool).value())
    }

    onMounted(() => {
      changedCount.value += 1

      emit('validate', chain(isValid.value).values().every(bool => bool).value())
    })

    return {
      // data
      salesRebateForm,
      changedCount,
      roundingMethods: [
        { value: 'round', text: '四捨五入' },
        { value: 'ceil', text: '切り上げ' },
        { value: 'floor', text: '切り捨て' },
      ],
      calcSalesTargets: [
        { icon: mdiCashMarker, value: 'contributed', text: '売上' },
        { icon: mdiHeartOutline, value: 'received', text: '受領' },
        { value: 'both', text: '両方' },
      ],

      // computed
      isValid,

      // methods
      change,
    }
  },
}
// TODO 売上計上トリガー用の専用のCRUD画面用意したほうがいい
</script>
