<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="rebates"
      :header-props="{ sortByText: 'ソート' }"
      hide-default-footer
      disable-pagination
      show-group-by
      no-data-text="データがありません"
    >
      <template #[`group.header`]="{ groupBy, group, remove }">
        <td
          :colspan="headers.length"
          @click="remove"
        >
          <span class="mr-4">{{ headers.find(obj => obj.value === groupBy[0]).text }}</span>

          <strong v-if="groupBy[0] === 'meta.targetName'">
            {{ group }}
          </strong>

          <strong v-if="groupBy[0] === 'attributes.amount'">
            <span v-if="+group < 1">{{ group * 100 }}%</span>
            <span v-else-if="+group === 1.0">1円または100%</span>
            <span v-else>¥{{ group.toLocaleString() }}</span>
          </strong>

          <strong v-if="groupBy[0] === 'attributes.counter'">
            {{ { currency_unit: '円', ratio: '%' }[group] }}
          </strong>

          <strong v-if="groupBy[0] === 'attributes.roundingMethod'">
            {{
              {
                round: '四捨五入',
                ceil: '切り上げ',
                floor: '切り捨て',
              }[group]
            }}
          </strong>

          <strong v-if="groupBy[0] === 'meta.calcSalesTargetName'">
            {{ group }}
          </strong>
        </td>
      </template>

      <template #[`item.meta.targetName`]="{ item }">
        <span class="d-flex align-center">
          {{ item.meta.targetName }}
          <small
            v-if="item.meta.sellingPrice"
            class="ml-2 text--secondary"
          >
            {{ `¥${item.meta.sellingPrice.toLocaleString()}` }}
          </small>

          <v-edit-dialog v-if="item.meta.vendibleTagVendibles.data.length > 0">
            <v-btn
              icon
              x-small
              right
              :ripple="false"
              class="ml-2"
            >
              <v-icon>{{ icons.mdiEyeOffOutline }}</v-icon>
            </v-btn>

            <template #input>
              <div style="max-height: 400px">
                <v-list-item
                  v-for="(vendibleTagVendible, vendibleTagVendibleIdx) in item.meta.vendibleTagVendibles.data"
                  :key="`${item.id}-vendibleTagVendibles[${vendibleTagVendibleIdx}]`"
                  class="secondary--text"
                >
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ vendibleTagVendible.attributes.name }}
                      <small class="ml-2 text--secondary">
                        {{ `¥${vendibleTagVendible.attributes.sellingPrice.toLocaleString()}` }}
                      </small>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </div>
            </template>
          </v-edit-dialog>
        </span>
      </template>

      <template #[`item.attributes.amount`]="{ item }">
        {{
          {
            currency_unit: amount => amount.toLocaleString(),
            ratio: amount => amount * 100,
          }[item.attributes.counter](item.attributes.amount)
        }}
      </template>

      <template #[`item.attributes.counter`]="{ item }">
        {{ { currency_unit: '円', ratio: '%' }[item.attributes.counter] }}
      </template>

      <template #[`item.meta.calcSalesTargetName`]="{ item }">
        {{ item.meta.calcSalesTargetName }}
      </template>

      <template #[`item.attributes.roundingMethod`]="{ item }">
        <span v-if="item.attributes.roundingMethod">
          {{
            {
              round: '四捨五入',
              ceil: '切り上げ',
              floor: '切り捨て',
            }[item.attributes.roundingMethod]
          }}
        </span>
      </template>

      <template #[`item.attributes.roundingDigit`]="{ item }">
        <span v-if="item.attributes.roundingDigit && item.attributes.roundingDigit < 0">
          {{
            {
              1: '一',
              2: '十',
              3: '百',
              4: '千',
              5: '万',
            }[Math.abs(item.attributes.roundingDigit)] + 'の位'
          }}
        </span>
      </template>

      <template #[`item.actions`]="{ item }">
        <slot
          name="action"
          :item="item"
        />
      </template>
    </v-data-table>
  </div>
</template>

<script>
import {
  mdiEyeOffOutline,
  mdiCashMarker,
  mdiHeartOutline,
} from '@mdi/js'

export default {
  props: {
    rebates: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  setup() {
    return {
      headers: [
        {
          text: '算出対象範囲',
          value: 'meta.calcSalesTargetName',
        },
        { text: 'ターゲット', value: 'meta.targetName' },
        {
          text: 'バック額',
          value: 'attributes.amount',
          align: 'right',
        },
        {
          text: '単位',
          value: 'attributes.counter',
          align: 'left',
        },
        {
          text: '丸め処理',
          value: 'attributes.roundingMethod',
          align: 'right',
        },
        {
          text: '丸める位',
          value: 'attributes.roundingDigit',
          align: 'left',
          groupable: false,
        },
        {
          text: '',
          value: 'actions',
          align: 'end',
          sortable: false,
          groupable: false,
        },
      ],

      icons: {
        mdiEyeOffOutline,
        mdiCashMarker,
        mdiHeartOutline,
      },
    }
  },
}
</script>
