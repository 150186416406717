var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-select',{attrs:{"value":_vm.value,"items":_vm.tableFilters,"item-value":function (item) {
          if (item.attributes) { return { tableFilterId: item.id } }
        },"value-comparator":function (a, b) {
          if (a && b) { return +a.tableFilterId === +b.tableFilterId }
        },"item-text":"attributes.name","label":"卓フィルタ","placeholder":"指定なし","persistent-placeholder":"","no-data-text":"選択可能なデータがありません","suffix":_vm.value.tableFilterId ? 'の卓の' : '',"clearable":"","menu-props":{ auto: true, offsetY: true }},on:{"change":function($event){return _vm.change('tableFilterId', $event ? $event.tableFilterId : null)},"click:clear":function($event){return _vm.change('tableFilterId', null)}}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-select',{attrs:{"value":_vm.value.calcSalesTarget,"items":_vm.calcSalesTargets,"item-value":"value","item-text":"text","label":"算出対象範囲","error":!_vm.isValid.calcSalesTarget,"menu-props":{ auto: true, offsetY: true }},on:{"change":function($event){return _vm.change('calcSalesTarget', $event)}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
        var item = ref.item;
return [_c('span',[(item.icon)?_c('v-icon',{attrs:{"left":"","small":""}},[_vm._v(" "+_vm._s(item.icon)+" ")]):_vm._e(),_vm._v(" "+_vm._s(item.text)+" ")],1)]}},{key:"item",fn:function(ref){
        var item = ref.item;
return [(item.icon)?_c('v-list-item-avatar',{attrs:{"size":"20"}},[_c('v-icon',[_vm._v(_vm._s(item.icon))])],1):_vm._e(),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(item.text)+" ")])],1)]}}])}),_c('v-checkbox',{staticClass:"mt-0 pt-0",attrs:{"input-value":_vm.value.withTax,"label":"大計","ripple":false},on:{"change":function($event){return _vm.change('withTax', !!$event)}}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"md":"6","cols":"12"}},[_c('v-text-field',{attrs:{"value":_vm.value.amount * 100.0,"type":"number","inputmode":"numeric","pattern":"[0-9]*","max":100,"error":!_vm.isValid.amount,"label":"バック額","suffix":"%","min":"1"},on:{"change":function($event){return _vm.change('amount', $event / 100.0)}}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"md":"6","cols":"12"}},[_c('v-select',{attrs:{"value":_vm.value.roundingMethod,"items":_vm.roundingMethods,"item-value":"value","item-text":"text","label":"丸め処理","error":!_vm.isValid.roundingMethod,"mandatory":"","menu-props":{ auto: true, offsetY: true }},on:{"change":function($event){return _vm.change('roundingMethod', $event)}}})],1),_c('v-spacer'),_c('v-col',{attrs:{"md":"6","cols":"12"}},[_c('v-slider',{attrs:{"value":Math.abs(_vm.value.roundingDigit),"tick-labels":['0', '一', '十', '百', '千', '万'],"min":0,"max":5,"label":"丸める位","step":"1","ticks":"always","tick-size":"4","error":!_vm.isValid.roundingDigit},on:{"change":function($event){return _vm.change('roundingDigit', $event * -1)}}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }