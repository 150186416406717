var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.rebates,"header-props":{ sortByText: 'ソート' },"hide-default-footer":"","disable-pagination":"","show-group-by":"","no-data-text":"データがありません"},scopedSlots:_vm._u([{key:"group.header",fn:function(ref){
var groupBy = ref.groupBy;
var group = ref.group;
var remove = ref.remove;
return [_c('td',{attrs:{"colspan":_vm.headers.length},on:{"click":remove}},[_c('span',{staticClass:"mr-4"},[_vm._v(_vm._s(_vm.headers.find(function (obj) { return obj.value === groupBy[0]; }).text))]),(groupBy[0] === 'attributes.amount')?_c('strong',[_c('span',[_vm._v(_vm._s(Math.round(group * 100))+"%")])]):_vm._e(),(groupBy[0] === 'attributes.roundingMethod')?_c('strong',[_vm._v(" "+_vm._s({ round: '四捨五入', ceil: '切り上げ', floor: '切り捨て', }[group])+" ")]):_vm._e(),(groupBy[0] === 'meta.calcSalesTargetName')?_c('strong',[_vm._v(" "+_vm._s(group)+" ")]):_vm._e()])]}},{key:"item.attributes.amount",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(Math.round(item.attributes.amount * 100))+"% ")]}},{key:"item.meta.calcSalesTargetName",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.meta.calcSalesTargetName)+" ")]}},{key:"item.attributes.roundingMethod",fn:function(ref){
var item = ref.item;
return [(item.attributes.roundingMethod)?_c('span',[_vm._v(" "+_vm._s({ round: '四捨五入', ceil: '切り上げ', floor: '切り捨て', }[item.attributes.roundingMethod])+" ")]):_vm._e()]}},{key:"item.attributes.roundingDigit",fn:function(ref){
var item = ref.item;
return [(item.attributes.roundingDigit && item.attributes.roundingDigit < 0)?_c('span',[_vm._v(" "+_vm._s({ 1: '一', 2: '十', 3: '百', 4: '千', 5: '万', }[Math.abs(item.attributes.roundingDigit)] + 'の位')+" ")]):_vm._e()]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_vm._t("action",null,{"item":item})]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }