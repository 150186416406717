<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="rebates"
      :header-props="{ sortByText: 'ソート' }"
      hide-default-footer
      disable-pagination
      show-group-by
      no-data-text="データがありません"
    >
      <template #[`group.header`]="{ groupBy, group, remove }">
        <td
          :colspan="headers.length"
          @click="remove"
        >
          <span class="mr-4">{{ headers.find(obj => obj.value === groupBy[0]).text }}</span>

          <strong v-if="groupBy[0] === 'meta.targetName'">
            {{ group }}
          </strong>

          <strong v-if="groupBy[0] === 'attributes.amount'">
            <span>¥{{ group.toLocaleString() }}</span>
          </strong>
        </td>
      </template>

      <template #[`item.attributes.amount`]="{ item }">
        <span>¥{{ item.attributes.amount.toLocaleString() }}</span>
      </template>

      <template #[`item.actions`]="{ item }">
        <slot
          name="action"
          :item="item"
        />
      </template>
    </v-data-table>
  </div>
</template>

<script>
export default {
  props: {
    rebates: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  setup() {
    return {
      headers: [
        { text: 'ターゲット', value: 'meta.targetName' },
        { text: 'バック額', value: 'attributes.amount' },
        {
          text: '',
          value: 'actions',
          align: 'end',
          sortable: false,
          groupable: false,
        },
      ],
    }
  },
}
</script>
