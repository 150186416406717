import { serialize } from 'object-to-formdata'
import snakecaseKeys from 'snakecase-keys'
import axiosIns from '@/plugins/axios'

export default {
  createReferringRebate({
    amount,
    referralId,
  }) {
    const formData = serialize(snakecaseKeys({
      referringRebate: {
        amount,
        referralId,
      },
    }))

    return axiosIns.post('/admin/referring_rebates', formData)
  },
  updateReferringRebate({
    id,
    amount,
    referralId,
  }) {
    const formData = serialize(snakecaseKeys({
      referringRebate: {
        id,
        amount,
        referralId,
      },
    }))

    return axiosIns.put(`/admin/referring_rebates/${id}`, formData)
  },
  deleteReferringRebate(id) {
    return axiosIns.delete(`/admin/referring_rebates/${id}`)
  },
}
