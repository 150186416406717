import { serialize } from 'object-to-formdata'
import snakecaseKeys from 'snakecase-keys'
import axiosIns from '@/plugins/axios'

export default {
  createVendingRebate({
    vendibleId,
    vendibleType,
    vendibleTagId,
    amount,
    counter,
    roundingMethod,
    roundingDigit,
    calcSalesTarget,
    tableFilterId,
    withTax,
  }) {
    const formData = serialize(snakecaseKeys({
      vendingRebate: {
        vendibleId,
        vendibleType,
        vendibleTagId,
        amount,
        counter,
        roundingMethod,
        roundingDigit,
        calcSalesTarget,
        tableFilterId,
        withTax,
      },
    }))

    return axiosIns.post('/admin/vending_rebates', formData)
  },
  updateVendingRebate({
    id,
    vendibleId,
    vendibleType,
    vendibleTagId,
    amount,
    counter,
    roundingMethod,
    roundingDigit,
    calcSalesTarget,
    tableFilterId,
    withTax,
  }) {
    const formData = serialize(snakecaseKeys({
      vendingRebate: {
        id,
        vendibleId,
        vendibleType,
        vendibleTagId,
        amount,
        counter,
        roundingMethod,
        roundingDigit,
        calcSalesTarget,
        tableFilterId,
        withTax,
      },
    }))

    return axiosIns.put(`/admin/vending_rebates/${id}`, formData)
  },
  deleteVendingRebate(id) {
    return axiosIns.delete(`/admin/vending_rebates/${id}`)
  },
}
