<template>
  <div>
    <v-select
      :value="value"
      :items="referrals"
      :item-value="item => {
        if (item.attributes) return { referralId: item.id }
      }"
      :value-comparator="(a, b) => {
        if (a && b) return +a.referralId === +b.referralId
      }"
      item-text="attributes.name"
      label="ターゲット"
      hide-details
      :error="!isValid.referralId"
      no-data-text="選択可能なデータがありません"
      :menu-props="{ auto: true, offsetY: true }"
      @change="change('referralId', $event.referralId)"
    />

    <v-row class="mt-3">
      <v-col
        md="6"
        cols="12"
      >
        <v-text-field
          :value="value.amount"
          type="number"
          inputmode="numeric"
          pattern="[0-9]*"
          :error="!isValid.amount"
          label="バック額"
          prefix="¥"
          min="1"
          @change="change('amount', $event)"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import {
  ref,
  computed,
  onMounted,
} from '@vue/composition-api'
import { useVModel } from '@vueuse/core'
import {
  chain,
  values,
  every,
  has,
} from 'lodash'

export default {
  props: {
    value: {
      type: Object,
      required: true,
      default: () => {},
    },
    referrals: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  setup(props, { emit }) {
    const referringRebateForm = useVModel(props, 'value')
    if (!has(referringRebateForm.value, 'amount')) referringRebateForm.value.amount = 1

    const changedCount = ref(0) // NOTE: valueが変更されるたびに再computeするために（propはリアクティブじゃないので）

    const isValid = computed(() => {
      // eslint-disable-next-line no-unused-expressions
      changedCount.value // NOTE: 単純な参照のみで変更を検知して再算出してくれる

      const {
        referralId,
        amount,
      } = referringRebateForm.value

      return {
        referralId,
        amount: amount && amount > 0,
      }
    })

    const change = (key, val) => {
      referringRebateForm.value[key] = val

      changedCount.value += 1

      emit('validate', chain(isValid.value).values().every(bool => bool).value())
    }

    onMounted(() => {
      changedCount.value += 1

      emit('validate', chain(isValid.value).values().every(bool => bool).value())
    })

    return {
      // data
      referringRebateForm,
      changedCount,

      // computed
      isValid,

      // methods
      change,
    }
  },
}
</script>
