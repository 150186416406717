<template>
  <div>
    <v-row>
      <v-col cols="6">
        <v-select
          :value="value"
          :items="tableFilters"
          :item-value="item => {
            if (item.attributes) return { tableFilterId: item.id }
          }"
          :value-comparator="(a, b) => {
            if (a && b) return +a.tableFilterId === +b.tableFilterId
          }"
          item-text="attributes.name"
          label="卓フィルタ"
          placeholder="指定なし"
          persistent-placeholder
          no-data-text="選択可能なデータがありません"
          :suffix="value.tableFilterId ? 'の卓の' : ''"
          clearable
          :menu-props="{ auto: true, offsetY: true }"
          @change="change('tableFilterId', $event ? $event.tableFilterId : null)"
          @click:clear="change('tableFilterId', null)"
        />
      </v-col>

      <v-col cols="6">
        <v-select
          :value="value.calcSalesTarget"
          :items="calcSalesTargets"
          item-value="value"
          item-text="text"
          label="算出対象範囲"
          :menu-props="{ auto: true, offsetY: true }"
          :error="!isValid.calcSalesTarget"
          @change="change('calcSalesTarget', $event)"
        >
          <template #selection="{ item }">
            <span>
              <v-icon
                v-if="item.icon"
                left
                small
              >
                {{ item.icon }}
              </v-icon>
              {{ item.text }}
            </span>
          </template>

          <template #item="{ item }">
            <v-list-item-avatar
              v-if="item.icon"
              size="20"
            >
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title>
                {{ item.text }}
              </v-list-item-title>
            </v-list-item-content>
          </template>
        </v-select>

        <v-checkbox
          :input-value="value.withTax"
          label="大計"
          :ripple="false"
          class="mt-0 pt-0"
          @change="change('withTax', !!$event)"
        />
      </v-col>
    </v-row>

    <v-radio-group
      v-model="relationType"
      row
    >
      <v-radio
        v-for="(text, key) in relationTypes"
        :key="`relationTypes[${key}]`"
        :label="text"
        :value="key"
        :disabled="!!value.id"
        @change="
          change('vendibleId', null);
          change('vendibleType', null);
          change('vendibleTagId', null);
          isOpenVendibleTagVendibles = false"
      ></v-radio>
    </v-radio-group>

    <v-select
      v-if="relationType === 'vendible'"
      :value="value"
      :items="vendibleItems"
      :item-value="item => {
        if (item.attributes) return { vendibleId: item.id, vendibleType: item.attributes.vendibleType }
      }"
      :value-comparator="(a, b) => {
        if (a && b) return +a.vendibleId === +b.vendibleId && a.vendibleType === b.vendibleType
      }"
      label="ターゲット"
      :error="!isValid.relation"
      no-data-text="選択可能なデータがありません"
      :menu-props="{ auto: true, offsetY: true }"
      @change="
        change('vendibleId', $event.vendibleId);
        change('vendibleType', $event.vendibleType);
        change('vendibleTagId', null)"
    >
      <template #selection="{ item }">
        <span>
          {{ item.attributes.name }}
          <small class="ml-2 text--secondary">
            {{ `¥${item.attributes.sellingPrice.toLocaleString()}` }}
          </small>
        </span>
      </template>

      <template #item="{ item }">
        <template v-if="!item.attributes">
          <v-list-item-content v-text="item.header" />
        </template>

        <v-list-item-content>
          <v-list-item-title>
            {{ item.attributes.name }}
            <small class="ml-2 text--secondary">
              {{ `¥${item.attributes.sellingPrice.toLocaleString()}` }}
            </small>
          </v-list-item-title>
        </v-list-item-content>
      </template>
    </v-select>

    <v-select
      v-if="relationType === 'vendibleTag'"
      :value="value"
      :items="vendibleTags"
      :item-value="item => {
        if (item.attributes) return { vendibleTagId: item.id }
      }"
      :value-comparator="(a, b) => {
        if (a && b) return +a.vendibleTagId === +b.vendibleTagId
      }"
      item-text="attributes.name"
      label="ターゲット"
      hide-details
      :error="!isValid.relation"
      :append-outer-icon="isOpenVendibleTagVendibles ? icons.mdiEye : icons.mdiEyeOffOutline"
      no-data-text="選択可能なデータがありません"
      :menu-props="{ auto: true, offsetY: true }"
      @change="
        change('vendibleTagId', $event.vendibleTagId);
        change('vendibleId', null);
        change('vendibleType', null)"
      @click:append-outer="isOpenVendibleTagVendibles = !isOpenVendibleTagVendibles"
    />
    <div v-if="isOpenVendibleTagVendibles">
      <div v-if="vendibleTagVendibles.length > 0">
        <v-list-item
          v-for="(vendibleTagVendible, vendibleTagVendibleIdx) in vendibleTagVendibles"
          :key="`vendibleTagVendibles[${vendibleTagVendibleIdx}]`"
          class="secondary--text"
        >
          <v-list-item-content>
            <v-list-item-title>
              {{ vendibleTagVendible.attributes.name }}
              <small class="ml-2 text--secondary">
                {{ `¥${vendibleTagVendible.attributes.sellingPrice.toLocaleString()}` }}
              </small>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </div>

      <span v-else>関連する商品がありません</span>
    </div>

    <v-row class="mt-3">
      <v-col cols="6">
        <v-text-field
          :value="value.counter === 'ratio' ? value.amount * 100.0 : value.amount"
          type="number"
          inputmode="numeric"
          pattern="[0-9]*"
          :max="max"
          :error="!isValid.amount"
          label="バック額"
          min="1"
          @change="change('amount', value.counter === 'ratio' ? $event / 100.0 : $event)"
        />
      </v-col>
      <v-col cols="6">
        <v-select
          :value="value.counter"
          :items="counters"
          item-value="value"
          item-text="text"
          label="単位"
          :error="!isValid.counter"
          hide-details
          :menu-props="{ auto: true, offsetY: true }"
          @change="
            change('counter', $event);
            change('amount', 1);
            change('roundingMethod', value.counter === 'currency_unit' ? 'round' : value.roundingMethod);
            change('roundingDigit', value.counter === 'currency_unit' ? null : value.roundingDigit);
            change('withTax', value.withTax === null ? false : value.withTax)"
        />
      </v-col>
    </v-row>

    <v-expand-transition>
      <v-row v-show="value.counter === 'ratio'">
        <v-col
          md="6"
          cols="12"
        >
          <v-select
            :value="value.roundingMethod"
            :items="roundingMethods"
            item-value="value"
            item-text="text"
            label="丸め処理"
            :error="!isValid.roundingMethod"
            mandatory
            :menu-props="{ auto: true, offsetY: true }"
            @change="change('roundingMethod', $event)"
          />
        </v-col>

        <v-spacer />

        <v-col
          md="6"
          cols="12"
        >
          <v-slider
            :value="Math.abs(value.roundingDigit)"
            :tick-labels="['0', '一', '十', '百', '千', '万']"
            :min="0"
            :max="5"
            label="丸める位"
            step="1"
            ticks="always"
            tick-size="4"
            :error="!isValid.roundingDigit"
            @change="change('roundingDigit', $event * -1)"
          />
        </v-col>
      </v-row>
    </v-expand-transition>
  </div>
</template>

<script>
import {
  ref,
  computed,
  onMounted,
} from '@vue/composition-api'
import { useVModel } from '@vueuse/core'
import {
  each,
  groupBy,
  filter,
  map,
  includes,
  chain,
  values,
  every,
  has,
} from 'lodash'
import {
  mdiEye,
  mdiEyeOffOutline,
  mdiCashMarker,
  mdiHeartOutline,
} from '@mdi/js'
import useCurrentData from '@/views/composable/useCurrentData'

export default {
  props: {
    value: {
      type: Object,
      required: true,
      default: () => {},
    },
    vendibles: {
      type: Array,
      required: true,
      default: () => [],
    },
    vendibleTags: {
      type: Array,
      required: true,
      default: () => [],
    },
    tableFilters: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  setup(props, { emit }) {
    const vendingRebateForm = useVModel(props, 'value')
    if (!has(vendingRebateForm.value, 'amount')) vendingRebateForm.value.amount = 1
    if (!has(vendingRebateForm.value, 'counter')) vendingRebateForm.value.counter = 'currency_unit'
    if (!has(vendingRebateForm.value, 'calcSalesTarget')) vendingRebateForm.value.calcSalesTarget = 'contributed'

    const relationType = ref('vendible')
    if (vendingRebateForm.value.id && vendingRebateForm.value.vendibleTagId) relationType.value = 'vendibleTag'
    const changedCount = ref(0) // NOTE: valueが変更されるたびに再computeするために（propはリアクティブじゃないので）
    const isOpenVendibleTagVendibles = ref(false)

    const { currentClub } = useCurrentData()

    const vendibleTypeMsg = computed(() => {
      return {
        Course: currentClub.value.courseAlias,
        Nomination: currentClub.value.nominationAlias,
        Item: 'アイテム',
      }
    })

    const vendibleItems = computed(() => {
      const lists = []
      const typeOrder = ['Course', 'Nomination', 'Item']
      const vendiblesGrouped = groupBy(props.vendibles, 'attributes.vendibleType')

      each(typeOrder, type => {
        if (!vendiblesGrouped[type]) return

        lists.push({ header: vendibleTypeMsg.value[type] })
        lists.push(...vendiblesGrouped[type])
      })

      return lists
    })

    const max = computed(() => {
      // eslint-disable-next-line no-unused-expressions
      changedCount.value // NOTE: 単純な参照のみで変更を検知して再算出してくれる

      return {
        currency_unit: Number.POSITIVE_INFINITY,
        ratio: 100,
      }[vendingRebateForm.value.counter]
    })

    const vendibleTagVendibles = computed(() => {
      // eslint-disable-next-line no-unused-expressions
      changedCount.value // NOTE: 単純な参照のみで変更を検知して再算出してくれる

      if (!vendingRebateForm.value.vendibleTagId) return []

      return filter(props.vendibles, vendible => {
        return includes(
          map(vendible.attributes.vendibleTaggings.data, d => +d.attributes.vendibleTagId),
          +vendingRebateForm.value.vendibleTagId,
        )
      })
    })

    const isValid = computed(() => {
      // eslint-disable-next-line no-unused-expressions
      changedCount.value // NOTE: 単純な参照のみで変更を検知して再算出してくれる

      const {
        vendibleId,
        vendibleType,
        vendibleTagId,
        amount,
        counter,
        roundingMethod,
        roundingDigit,
        calcSalesTarget,
      } = vendingRebateForm.value
      const isValidRelationVendible = vendibleId && vendibleType && !vendibleTagId
      const isValidRelationVendibleTag = !vendibleId && !vendibleType && vendibleTagId

      return {
        relation: isValidRelationVendible || isValidRelationVendibleTag,
        amount: amount && (amount * 100) <= max.value,
        counter,
        roundingMethod: counter === 'ratio' ? roundingMethod : true,
        roundingDigit: counter === 'ratio' ? roundingDigit <= 0 : true,
        calcSalesTarget,
      }
    })

    const change = (key, val) => {
      vendingRebateForm.value[key] = val

      changedCount.value += 1

      emit('validate', chain(isValid.value).values().every(bool => bool).value())
    }

    onMounted(() => {
      changedCount.value += 1

      emit('validate', chain(isValid.value).values().every(bool => bool).value())
    })

    return {
      // data
      vendingRebateForm,
      relationType,
      changedCount,
      isOpenVendibleTagVendibles,
      relationTypes: {
        vendible: '商品',
        vendibleTag: '商品タグ',
      },
      counters: [
        { value: 'currency_unit', text: '円' },
        { value: 'ratio', text: '%' },
      ],
      roundingMethods: [
        { value: 'round', text: '四捨五入' },
        { value: 'ceil', text: '切り上げ' },
        { value: 'floor', text: '切り捨て' },
      ],
      calcSalesTargets: [
        { icon: mdiCashMarker, value: 'contributed', text: '売上' },
        { icon: mdiHeartOutline, value: 'received', text: '受領' },
        { value: 'both', text: '両方' },
      ],

      // computed
      vendibleItems,
      vendibleTagVendibles,
      max,
      isValid,

      // methods
      change,

      icons: {
        mdiEye,
        mdiEyeOffOutline,
      },
    }
  },
}
</script>
