var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-select',{attrs:{"value":_vm.value,"items":_vm.tableFilters,"item-value":function (item) {
          if (item.attributes) { return { tableFilterId: item.id } }
        },"value-comparator":function (a, b) {
          if (a && b) { return +a.tableFilterId === +b.tableFilterId }
        },"item-text":"attributes.name","label":"卓フィルタ","placeholder":"指定なし","persistent-placeholder":"","no-data-text":"選択可能なデータがありません","suffix":_vm.value.tableFilterId ? 'の卓の' : '',"clearable":"","menu-props":{ auto: true, offsetY: true }},on:{"change":function($event){return _vm.change('tableFilterId', $event ? $event.tableFilterId : null)},"click:clear":function($event){return _vm.change('tableFilterId', null)}}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-select',{attrs:{"value":_vm.value.calcSalesTarget,"items":_vm.calcSalesTargets,"item-value":"value","item-text":"text","label":"算出対象範囲","menu-props":{ auto: true, offsetY: true },"error":!_vm.isValid.calcSalesTarget},on:{"change":function($event){return _vm.change('calcSalesTarget', $event)}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
        var item = ref.item;
return [_c('span',[(item.icon)?_c('v-icon',{attrs:{"left":"","small":""}},[_vm._v(" "+_vm._s(item.icon)+" ")]):_vm._e(),_vm._v(" "+_vm._s(item.text)+" ")],1)]}},{key:"item",fn:function(ref){
        var item = ref.item;
return [(item.icon)?_c('v-list-item-avatar',{attrs:{"size":"20"}},[_c('v-icon',[_vm._v(_vm._s(item.icon))])],1):_vm._e(),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(item.text)+" ")])],1)]}}])}),_c('v-checkbox',{staticClass:"mt-0 pt-0",attrs:{"input-value":_vm.value.withTax,"label":"大計","ripple":false},on:{"change":function($event){return _vm.change('withTax', !!$event)}}})],1)],1),_c('v-radio-group',{attrs:{"row":""},model:{value:(_vm.relationType),callback:function ($$v) {_vm.relationType=$$v},expression:"relationType"}},_vm._l((_vm.relationTypes),function(text,key){return _c('v-radio',{key:("relationTypes[" + key + "]"),attrs:{"label":text,"value":key,"disabled":!!_vm.value.id},on:{"change":function($event){_vm.change('vendibleId', null);
        _vm.change('vendibleType', null);
        _vm.change('vendibleTagId', null);
        _vm.isOpenVendibleTagVendibles = false}}})}),1),(_vm.relationType === 'vendible')?_c('v-select',{attrs:{"value":_vm.value,"items":_vm.vendibleItems,"item-value":function (item) {
      if (item.attributes) { return { vendibleId: item.id, vendibleType: item.attributes.vendibleType } }
    },"value-comparator":function (a, b) {
      if (a && b) { return +a.vendibleId === +b.vendibleId && a.vendibleType === b.vendibleType }
    },"label":"ターゲット","error":!_vm.isValid.relation,"no-data-text":"選択可能なデータがありません","menu-props":{ auto: true, offsetY: true }},on:{"change":function($event){_vm.change('vendibleId', $event.vendibleId);
      _vm.change('vendibleType', $event.vendibleType);
      _vm.change('vendibleTagId', null)}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
      var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.attributes.name)+" "),_c('small',{staticClass:"ml-2 text--secondary"},[_vm._v(" "+_vm._s(("¥" + (item.attributes.sellingPrice.toLocaleString())))+" ")])])]}},{key:"item",fn:function(ref){
      var item = ref.item;
return [(!item.attributes)?[_c('v-list-item-content',{domProps:{"textContent":_vm._s(item.header)}})]:_vm._e(),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(item.attributes.name)+" "),_c('small',{staticClass:"ml-2 text--secondary"},[_vm._v(" "+_vm._s(("¥" + (item.attributes.sellingPrice.toLocaleString())))+" ")])])],1)]}}],null,false,3748544562)}):_vm._e(),(_vm.relationType === 'vendibleTag')?_c('v-select',{attrs:{"value":_vm.value,"items":_vm.vendibleTags,"item-value":function (item) {
      if (item.attributes) { return { vendibleTagId: item.id } }
    },"value-comparator":function (a, b) {
      if (a && b) { return +a.vendibleTagId === +b.vendibleTagId }
    },"item-text":"attributes.name","label":"ターゲット","hide-details":"","error":!_vm.isValid.relation,"append-outer-icon":_vm.isOpenVendibleTagVendibles ? _vm.icons.mdiEye : _vm.icons.mdiEyeOffOutline,"no-data-text":"選択可能なデータがありません","menu-props":{ auto: true, offsetY: true }},on:{"change":function($event){_vm.change('vendibleTagId', $event.vendibleTagId);
      _vm.change('vendibleId', null);
      _vm.change('vendibleType', null)},"click:append-outer":function($event){_vm.isOpenVendibleTagVendibles = !_vm.isOpenVendibleTagVendibles}}}):_vm._e(),(_vm.isOpenVendibleTagVendibles)?_c('div',[(_vm.vendibleTagVendibles.length > 0)?_c('div',_vm._l((_vm.vendibleTagVendibles),function(vendibleTagVendible,vendibleTagVendibleIdx){return _c('v-list-item',{key:("vendibleTagVendibles[" + vendibleTagVendibleIdx + "]"),staticClass:"secondary--text"},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(vendibleTagVendible.attributes.name)+" "),_c('small',{staticClass:"ml-2 text--secondary"},[_vm._v(" "+_vm._s(("¥" + (vendibleTagVendible.attributes.sellingPrice.toLocaleString())))+" ")])])],1)],1)}),1):_c('span',[_vm._v("関連する商品がありません")])]):_vm._e(),_c('v-row',{staticClass:"mt-3"},[_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"value":_vm.value.counter === 'ratio' ? _vm.value.amount * 100.0 : _vm.value.amount,"type":"number","inputmode":"numeric","pattern":"[0-9]*","max":_vm.max,"error":!_vm.isValid.amount,"label":"バック額","min":"1"},on:{"change":function($event){return _vm.change('amount', _vm.value.counter === 'ratio' ? $event / 100.0 : $event)}}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-select',{attrs:{"value":_vm.value.counter,"items":_vm.counters,"item-value":"value","item-text":"text","label":"単位","error":!_vm.isValid.counter,"hide-details":"","menu-props":{ auto: true, offsetY: true }},on:{"change":function($event){_vm.change('counter', $event);
          _vm.change('amount', 1);
          _vm.change('roundingMethod', _vm.value.counter === 'currency_unit' ? 'round' : _vm.value.roundingMethod);
          _vm.change('roundingDigit', _vm.value.counter === 'currency_unit' ? null : _vm.value.roundingDigit);
          _vm.change('withTax', _vm.value.withTax === null ? false : _vm.value.withTax)}}})],1)],1),_c('v-expand-transition',[_c('v-row',{directives:[{name:"show",rawName:"v-show",value:(_vm.value.counter === 'ratio'),expression:"value.counter === 'ratio'"}]},[_c('v-col',{attrs:{"md":"6","cols":"12"}},[_c('v-select',{attrs:{"value":_vm.value.roundingMethod,"items":_vm.roundingMethods,"item-value":"value","item-text":"text","label":"丸め処理","error":!_vm.isValid.roundingMethod,"mandatory":"","menu-props":{ auto: true, offsetY: true }},on:{"change":function($event){return _vm.change('roundingMethod', $event)}}})],1),_c('v-spacer'),_c('v-col',{attrs:{"md":"6","cols":"12"}},[_c('v-slider',{attrs:{"value":Math.abs(_vm.value.roundingDigit),"tick-labels":['0', '一', '十', '百', '千', '万'],"min":0,"max":5,"label":"丸める位","step":"1","ticks":"always","tick-size":"4","error":!_vm.isValid.roundingDigit},on:{"change":function($event){return _vm.change('roundingDigit', $event * -1)}}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }