import { serialize } from 'object-to-formdata'
import snakecaseKeys from 'snakecase-keys'
import axiosIns from '@/plugins/axios'

export default {
  createSalesRebate({
    amount,
    counter,
    roundingMethod,
    roundingDigit,
    calcSalesTarget,
    tableFilterId,
    withTax,
  }) {
    const formData = serialize(snakecaseKeys({
      salesRebate: {
        amount,
        counter,
        roundingMethod,
        roundingDigit,
        calcSalesTarget,
        tableFilterId,
        withTax,
      },
    }))

    return axiosIns.post('/admin/sales_rebates', formData)
  },
  updateSalesRebate({
    id,
    amount,
    counter,
    roundingMethod,
    roundingDigit,
    calcSalesTarget,
    tableFilterId,
    withTax,
  }) {
    const formData = serialize(snakecaseKeys({
      salesRebate: {
        id,
        amount,
        counter,
        roundingMethod,
        roundingDigit,
        calcSalesTarget,
        tableFilterId,
        withTax,
      },
    }))

    return axiosIns.put(`/admin/sales_rebates/${id}`, formData)
  },
  deleteSalesRebate(id) {
    return axiosIns.delete(`/admin/sales_rebates/${id}`)
  },
}
