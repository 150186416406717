<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="rebates"
      :header-props="{ sortByText: 'ソート' }"
      hide-default-footer
      disable-pagination
      show-group-by
      no-data-text="データがありません"
    >
      <template #[`group.header`]="{ groupBy, group, remove }">
        <td
          :colspan="headers.length"
          @click="remove"
        >
          <span class="mr-4">{{ headers.find(obj => obj.value === groupBy[0]).text }}</span>

          <strong v-if="groupBy[0] === 'attributes.amount'">
            <span>{{ Math.round(group * 100) }}%</span>
          </strong>

          <strong v-if="groupBy[0] === 'attributes.roundingMethod'">
            {{
              {
                round: '四捨五入',
                ceil: '切り上げ',
                floor: '切り捨て',
              }[group]
            }}
          </strong>

          <strong v-if="groupBy[0] === 'meta.calcSalesTargetName'">
            {{ group }}
          </strong>
        </td>
      </template>

      <template #[`item.attributes.amount`]="{ item }">
        {{ Math.round(item.attributes.amount * 100) }}%
      </template>

      <template #[`item.meta.calcSalesTargetName`]="{ item }">
        {{ item.meta.calcSalesTargetName }}
      </template>

      <template #[`item.attributes.roundingMethod`]="{ item }">
        <span v-if="item.attributes.roundingMethod">
          {{
            {
              round: '四捨五入',
              ceil: '切り上げ',
              floor: '切り捨て',
            }[item.attributes.roundingMethod]
          }}
        </span>
      </template>

      <template #[`item.attributes.roundingDigit`]="{ item }">
        <span v-if="item.attributes.roundingDigit && item.attributes.roundingDigit < 0">
          {{
            {
              1: '一',
              2: '十',
              3: '百',
              4: '千',
              5: '万',
            }[Math.abs(item.attributes.roundingDigit)] + 'の位'
          }}
        </span>
      </template>

      <template #[`item.actions`]="{ item }">
        <slot
          name="action"
          :item="item"
        />
      </template>
    </v-data-table>
  </div>
</template>

<script>
import {
  mdiCashMarker,
  mdiHeartOutline,
} from '@mdi/js'

export default {
  props: {
    rebates: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  setup() {
    return {
      headers: [
        {
          text: '算出対象範囲',
          value: 'meta.calcSalesTargetName',
        },
        { text: 'バック額', value: 'attributes.amount' },
        {
          text: '丸め処理',
          value: 'attributes.roundingMethod',
          align: 'right',
        },
        {
          text: '丸める位',
          value: 'attributes.roundingDigit',
          align: 'left',
          groupable: false,
        },
        {
          text: '',
          value: 'actions',
          align: 'end',
          sortable: false,
          groupable: false,
        },
      ],

      icons: {
        mdiCashMarker,
        mdiHeartOutline,
      },
    }
  },
}
</script>
